export const CONFIG = JSON.parse(process.env.CONFIG || '{}');
export const REDUX_STORAGE_KEY = '__REDUX_STORAGE__';

export const IS_DEV = process.env.NODE_ENV !== 'production';
export const CAN_USE_DOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

export const SHARE_CONSTANTS = {
  WHATSAPP: 'Whatsapp',
  FACEBOOK: 'Facebook',
};

export const CSV_FIELDS_LABELS = {
  FALLEN_NAME: 'שם החלל המלא',
  FULL_NAME: 'שם השולח',
  EMAIL: 'אימייל',
  IMAGE: 'תמונה',
  PHONE: 'טלפון',
  PHOTO_CREDIT: 'קרדיט תמונה',
  STORY: 'תיאור',
  SONG_NAME: 'שם השיר והאמן',
  CONNECTION_TYPE: 'קרבה',
  CREATED_DATE: 'תאריך יצירה',
};
