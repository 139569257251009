import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'client/hooks';

import RadioButton from '../RadioButton';
import css from './RadioButtonsGroup.module.scss';

interface Props {
  className?: string;
  name?: string;
  values?: Array<string>;
  title?: string;
  error?: string | any;
}

const RadioButtonsGroup: React.FC<Props> = (props) => {
  const { className = '', name = '', values = [], title = '', error } = props;
  const { translate } = useTranslation('errors');

  return (
    <div className={cn(css.radioBtnsGroup, className)}>
      <div className={css.radioBtns}>
        {values.map((item, index) => {
          return (
            <div className={cn(css.fieldWrapper, css.radio, css[`radio${index + 1}`])} key={item}>
              <RadioButton name={name} value={item} />
            </div>
          );
        })}
      </div>
      {error && <span className={css.error}>{translate(`${name}.${error}`)}</span>}
    </div>
  );
};

export default RadioButtonsGroup;
