import { CAN_USE_DOM, IS_DEV } from 'constants/index';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

type eventsKeysT = 'event' | 'Category' | 'Action' | 'Label';

type eventsResultT = Record<eventsKeysT, string>;

type eventsParamsT = eventsResultT;

type pageViewKeysT = 'event' | 'virtualPageURL' | 'virtualPageTitle';

type pageViewResultT = Record<pageViewKeysT, string>;

type pageViewParamsT = pageViewResultT;

if (CAN_USE_DOM) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ hostname: 'their-song-form.ynet.co.il' });
}

export class dataLayerEvents {
  private pageViewWrapFunction = ({ event, virtualPageURL, virtualPageTitle }: pageViewParamsT) => {
    const resultObject: pageViewResultT = {
      event,
      virtualPageURL,
      virtualPageTitle,
    };

    return !IS_DEV && window.dataLayer.push(resultObject);
    // return window.dataLayer.push(resultObject);
  };

  private eventWrapFunction = ({ Action, Label, Category, event }: eventsParamsT) => {
    const resultObject: eventsResultT = {
      event,
      Category,
      Action,
      Label,
    };

    return !IS_DEV && window.dataLayer.push(resultObject);
    // return window.dataLayer.push(resultObject);
  };

  public onPageView = (): void => {
    const params: pageViewParamsT = {
      event: 'virtualPageview',
      virtualPageURL: 'https://their-song-form.ynet.co.il',
      virtualPageTitle: 'לבכות לכם – השירים שאהבו הנופלים והנרצחים במלחמת חרבות ברזל',
    };

    this.pageViewWrapFunction(params);
  };

  public onShareClick = (payload: string): void => {
    const params: eventsParamsT = {
      event: 'share_events',
      Action: payload,
      Label: 'their-song-form.ynet.co.il',
      Category: 'Share',
    };

    this.eventWrapFunction(params);
  };

  public onFormSent = (payload: string): void => {
    const params: eventsParamsT = {
      Action: `send_form`,
      Label: payload,
      Category: 'Click',
      event: 'sent_leads',
    };

    this.eventWrapFunction(params);
  };
}
