import React from 'react';
import classNames from 'classnames';

import { useResponsive, useTranslation } from 'client/hooks';

import logo from 'client/assets/top/logo.svg';
import css from './TopSection.module.scss';

const TopSection: React.FC = () => {
  const [isMobile] = useResponsive('MOBILE');
  const { translate } = useTranslation('topSection');

  return (
    <section className={css.topSection}>
      <div className={css.info}>
        <div className={css.logoWrapper}>
          <img src={logo} alt="logo" />
        </div>
        <p className={classNames(css.text, css.paragraph1)}>{translate('paragraph1')}</p>
        <p
          className={classNames(css.text, css.paragraph2)}
          dangerouslySetInnerHTML={{ __html: translate('paragraph2') }}
        ></p>
        <p
          className={classNames(css.text, css.paragraph3)}
          dangerouslySetInnerHTML={{ __html: translate('paragraph3') }}
        ></p>
        <p
          className={classNames(css.text, css.paragraph4)}
          dangerouslySetInnerHTML={{ __html: translate('paragraph4') }}
        ></p>
      </div>
    </section>
  );
};

export default TopSection;
