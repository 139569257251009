import * as React from 'react';
import cn from 'classnames';
import { useField } from 'formik';
import { useTranslation } from 'client/hooks';
import css from './Checkbox.module.scss';

interface Props {
  className?: string;
  name?: string;
  label: string;
  isBig?: boolean;
}

const Checkbox: React.FC<Props> = (props) => {
  const { className = '', name = '', label, isBig = true } = props;
  const [field, meta] = useField(name);
  const { translate } = useTranslation('errors');

  return (
    <div className={cn(css.checkbox, className, isBig && css.big)}>
      <label>
        <div className={css.inputWrapper}>
          <input {...field} type="checkbox" checked={field.value} />
          <span></span>
        </div>
        <div className={css.confirmation} dangerouslySetInnerHTML={{ __html: label }} />
      </label>
      {meta.touched && !!meta.error && <p className={css.error}>{translate(`${name}.${meta.error}`)}</p>}
    </div>
  );
};

export default Checkbox;
