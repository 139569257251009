import React from 'react';
import { useField } from 'formik';
import cn from 'classnames';
import _ from 'lodash';
import { useTranslation } from 'client/hooks';

import css from './TextArea.module.scss';

const FIELD_LIMIT = 1500;

interface Props {
  classname?: string;
  name?: string;
  placeholder?: string;
  wordsLimitText?: string;
}

const TextArea: React.FC<Props> = (props) => {
  const { classname = '', name = '', placeholder = '', wordsLimitText = '' } = props;
  const { translate } = useTranslation('errors');
  const [field, meta, helpers] = useField(name);
  const totalWords = field.value ? field.value.split(/\r\n|\r|\n|\s/).length : 0;

  const countLimitLabel =
    FIELD_LIMIT - totalWords < 0
      ? wordsLimitText.replace('{{count}}', `0`)
      : wordsLimitText.replace('{{count}}', `${FIELD_LIMIT - totalWords}`);

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    helpers.setValue(e.target.value);
  };

  return (
    <div className={css.container}>
      <textarea {...field} onChange={handleOnChange} className={cn(css.textarea, classname)} rows={4} cols={50} />
      {!field.value && <p className={css.placeholder} dangerouslySetInnerHTML={{ __html: placeholder }}></p>}
      <span className={css.error}>{meta.error && meta.touched && translate(`${name}.${meta.error}`)}</span>
      {/* <div className={css.wordsLimit}>{countLimitLabel}</div> */}
    </div>
  );
};

export default TextArea;
