import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useField } from 'formik';
import { useResponsive, useTranslation } from 'client/hooks';

import mediaBtn from 'client/assets/form/media-button.svg';

import css from './MediaField.module.scss';

interface Props {
  className?: string;
  name: string;
  value?: string | ArrayBuffer | null | undefined | File;
  onChange?: (e: React.ChangeEvent<any>) => void;
  errors?: Record<string, string>;
  placeholder?: string;
}

interface State {
  img: string | ArrayBuffer | null | undefined | File;
}

const initialState: State = {
  img: null,
};

type Action = {
  type: 'setImg';
  payload: State['img'];
};

function reducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case 'setImg':
      return { ...state, img: action.payload };

    default:
      throw new Error('Unknown action');
  }
}

const MediaField: React.FC<Props> = (props) => {
  const { className = '', name, placeholder } = props;
  const { translate } = useTranslation('errors');
  const fileRef = React.useRef<any>(null);
  const [state, dispatch] = React.useReducer(reducer, { ...initialState, img: props.value });
  const [field, meta, helpers] = useField(name);
  const isErrorVisible = meta.touched && !!meta.error;
  const isWithValue = meta.value;

  const onChange = (e: React.ChangeEvent<any>) => {
    helpers.setTouched(true);
    const reader = new FileReader();

    reader.onloadend = () => dispatch({ type: 'setImg', payload: reader.result });
    reader.readAsDataURL(e.currentTarget.files[0]);
    helpers.setValue(e.currentTarget.files[0]);
    if (props.onChange) props.onChange(e);
  };

  useEffect(() => {
    dispatch({ type: 'setImg', payload: meta.value });
  }, [meta.value]);

  return (
    <>
      <div className={classNames(css.mediaField, className, isErrorVisible && css.error)}>
        <label>
          <div
            className={classNames(css.wrapper, isWithValue && css.withValue)}
            title={meta.value.name}
            dangerouslySetInnerHTML={{ __html: isWithValue ? meta.value.name : placeholder }}
          ></div>
          <div className={css.btn} style={{ backgroundImage: `url(${mediaBtn})` }}></div>
          <input type="file" name={name} ref={fileRef} onChange={onChange} accept="image/png, image/jpeg, image/jpg" />
        </label>
        {isErrorVisible && <p className={css.errorMsg}>{translate(`image.${meta.error}`)}</p>}
      </div>
    </>
  );
};

export default MediaField;
