import * as React from 'react';

import { useResponsive, useTranslation } from 'client/hooks';
import { share } from 'utils/share';
import { IS_DEV, SHARE_CONSTANTS } from 'constants/index';
import { dataLayerEvents } from 'client/utils/gtag';

import logo1 from 'client/assets/header/logo1.svg';
import logo2 from 'client/assets/header/logo2.svg';
import logo3 from 'client/assets/header/logo3.svg';

import fbIcon from 'client/assets/header/face.svg';
import whatsappIcon from 'client/assets/header/wt.svg';
import mailIcon from 'client/assets/header/mail.svg';

import css from './Header.module.scss';

interface Props {
  className?: string;
}

const Header: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const [isDesktop] = useResponsive('DESKTOP');
  const { translate: seoTexts } = useTranslation('seo');
  const dataLayerInstance = new dataLayerEvents();

  const onShare = (e: React.SyntheticEvent<HTMLButtonElement>): void => {
    const type = `${e.currentTarget.dataset.id}`;
    const seo = {
      title: seoTexts('title'),
      description: seoTexts('description'),
    };

    if (!IS_DEV) {
      share(type, isDesktop, seo);
    }

    dataLayerInstance.onShareClick(type);
  };

  const clickOnMail = () => {
    dataLayerInstance.onShareClick('Mail');
  };

  return (
    <header>
      <div className={css.headerWrapper}>
        <div className={css.logosWrapper}>
          <a href="/">
            <img src={logo1} alt="logo" />
          </a>
          <a href="/">
            <img src={logo2} alt="logo" />
          </a>
          <a href="/">
            <img src={logo3} alt="logo" />
          </a>
        </div>
        <div className={css.socialls}>
          <a href="mailto:" className={css.socialButton} onClick={clickOnMail}>
            <img src={mailIcon} alt="" />
          </a>
          <button className={css.socialButton} onClick={onShare} data-id={SHARE_CONSTANTS.WHATSAPP}>
            <img src={whatsappIcon} alt="whatsapp icon" />
          </button>
          <button className={css.socialButton} onClick={onShare} data-id={SHARE_CONSTANTS.FACEBOOK}>
            <img src={fbIcon} alt="facebook icon" />
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
