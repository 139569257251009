import * as React from 'react';
import cn from 'classnames';
import { useField } from 'formik';
import css from './RadioButton.module.scss';

interface Props {
  className?: string;
  name?: string;
  value?: string;
}

const RadioButton: React.FC<Props> = (props) => {
  const { className = '', name = '', value = '' } = props;
  const [field, meta] = useField(name);

  const fieldProps = { ...field, value };

  return (
    <div className={cn(css.radio, className)}>
      <label>
        <div className={cn(css.inputWrapper, value === field.value && css.active)}>
          <input {...fieldProps} type="radio" />
        </div>

        <div className={cn(css.value, !!meta.error && css.error)}>{value}</div>
      </label>
    </div>
  );
};

export default RadioButton;
