import React from 'react';
import cn from 'classnames';

import { useTranslation } from 'client/hooks';

import Header from 'client/components/common/Header';
import TopSection from 'client/components/common/TopSection';
import FormMain from 'client/components/common/Form';
import { dataLayerEvents } from 'client/utils/gtag';
import Footer from 'client/components/common/Footer';

import mainBackground from 'client/assets/main-background.png';

import css from './Intro.module.scss';

interface Props {
  className?: string;
}

const Main: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const { translate } = useTranslation('general');
  const dataLayerInstance = new dataLayerEvents();

  React.useEffect(() => {
    dataLayerInstance.onPageView();
  }, []);

  return (
    <div className={cn(css.main, className)}>
      <Header />
      <TopSection />
      <FormMain />
      <div className={css.background} style={{ backgroundImage: `url(${mainBackground})` }}></div>
    </div>
  );
};

export default Main;
